import React, { useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Field } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { OTPAuthCodeField } from "@js/apps/common/forms/fields/otp/otp-auth-code";
import { QRCode } from "@js/components/qrcode";
import { PasswordField } from "@js/forms/fields/password";
import { required } from "@js/forms/validators";

import styles from "./styles.module.scss";

type EnableOTPAuthConentProps = {
  OTPAuthURI: string;
  OTPAuthSecret: string;
  isRecreateMode?: boolean;
  renderPasswordField?: boolean;
  render2FAFields?: boolean;
};

export const EnableOTPContent = ({
  OTPAuthURI,
  OTPAuthSecret,
  renderPasswordField = true,
}: EnableOTPAuthConentProps) => {
  const isMobileDevice = isMobile || isTablet;

  return (
    <ol id="2fa-instructions-list" className={styles.list}>
      {renderPasswordField && (
        <>
          <OtpLiComponent text="Enter the current account password." />
          <OTPConfirmPasswordField />
        </>
      )}
      <Box mt={2}>
        <OtpLiComponent
          text="Install Authy, Google Authenticator, or another authenticator app of
      your choice."
        />
        {isMobileDevice ? (
          <MobileContent OTPAuthSecret={OTPAuthSecret} />
        ) : (
          <DesktopContent
            OTPAuthURI={OTPAuthURI}
            OTPAuthSecret={OTPAuthSecret}
          />
        )}
        <OtpLiComponent text="Enter the 6-digit code generated by your authenticator app." />
        <OTPAuthCodeField
          name="code"
          inputProps={{
            "aria-describedby": "2fa-instructions-list",
            autoFocus: !renderPasswordField,
          }}
        />
      </Box>
    </ol>
  );
};

const DesktopContent = ({
  OTPAuthURI,
  OTPAuthSecret,
}: {
  OTPAuthURI: string;
  OTPAuthSecret: string;
}) => {
  const [showSecret, setShowSecret] = useState(false);

  const handleShowSecret = () => {
    setShowSecret(!showSecret);
  };

  return (
    <>
      <OtpLiComponent text="Open the app and find the “Scan a barcode/QR code” option." />
      <OtpLiComponent text="Scan the QR code below.">
        <br />
        <div className="center">
          <QRCode value={OTPAuthURI} />
          <Typography component="p" variant="paragraph" size="small">
            Unable to scan? You can use the{" "}
            <ClickableText onClick={handleShowSecret}>setup key</ClickableText>{" "}
            to manually configure your authenticator app.
          </Typography>
          {showSecret && (
            <Typography
              component="p"
              variant="paragraph"
              fontWeight={500}
              mt={2}
            >
              {OTPAuthSecret}
            </Typography>
          )}
        </div>
      </OtpLiComponent>
    </>
  );
};

const MobileContent = ({ OTPAuthSecret }: { OTPAuthSecret: string }) => {
  return (
    <OtpLiComponent text="Open the app and enter the setup key below.">
      <Typography
        component="p"
        variant="paragraph"
        fontWeight={500}
        textAlign="center"
        mt={3}
      >
        {OTPAuthSecret}
      </Typography>
    </OtpLiComponent>
  );
};

const OtpLiComponent = ({
  text,
  children,
}: {
  text: string;
  children?: React.ReactNode;
}) => {
  return (
    <li>
      <Typography component="p" variant="paragraph">
        {text}
      </Typography>
      {children && children}
    </li>
  );
};

const OTPConfirmPasswordField = () => {
  return (
    <Field
      validate={[required]}
      component={PasswordField}
      normalize={(value) => value}
      //special data atribute to prevent LastPass from filling this field
      data-lpignore="true"
      id="current_password"
      name="current_password"
      label=""
      color="secondary"
      variant="outlined"
      inputProps={{
        "aria-label": "password",
      }}
      autoFocus
    />
  );
};

const ClickableText = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <span
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick();
        }
      }}
    >
      {children}
    </span>
  );
};
