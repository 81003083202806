import { type FC } from "react";

import { Box } from "@hexocean/braintrust-ui-components";

import { BTRSTCheckoutForm } from "./btrst-checkout-form";
import { HeaderSection } from "./header-section";
import { useProductCheckoutModalContext } from "./product-checkout-modal-context";
import { StripeCheckoutForm } from "./stripe-checkout-form";

export const ProductCheckoutModal: FC = () => {
  const { modeOfPayment } = useProductCheckoutModalContext();

  return (
    <Box display="flex" gap={2.5} flexDirection="column" height="100%">
      <Box pt={{ xs: 2.5, md: 4 }} px={{ xs: 2.5, md: 4 }}>
        <HeaderSection />
      </Box>
      <Box flex={1} display="flex" sx={{ overflowY: "auto" }}>
        {modeOfPayment === "stripe" && <StripeCheckoutForm />}
        {modeOfPayment === "btrst" && (
          <Box
            width={{ xs: "100%", md: "690px" }}
            display="inherit"
            flex={1}
            pb={{ xs: 2.5, md: 4 }}
            px={{ xs: 2.5, md: 4 }}
          >
            <BTRSTCheckoutForm />
          </Box>
        )}
      </Box>
    </Box>
  );
};
